import { createReducer, on } from '@ngrx/store';
import { storeGeozones } from '../actions/geozones.actions';

export const initialState: any = {
  list: [],
};

export const geozonesReducer = createReducer(
  initialState,
  on(storeGeozones, (state, { list }) => ({ ...state, list })),
);
