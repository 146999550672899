import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { finalize, Observable } from 'rxjs';
import { AppService } from '@services/app.service';
import { environment } from '../../environments/environment';

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
  requestMethodsAutoload: string[] = ['POST', 'PUT', 'DELETE'];

  skipRequestsUrl: string[] = [
    `${environment.apiUrl}geocoding/reverse/`,
    `${environment.apiUrl}messages/get`,
    `${environment.apiUrl}messages/stat`,
    `${environment.apiUrl}tracks/create/`,
    `${environment.apiUrl}objects/send_command/`,
    'https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address',
  ];

  constructor(private appService: AppService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const isAutoLoad =
      this.requestMethodsAutoload.includes(request.method) &&
      !this.skipRequestsUrl.includes(request.url) &&
      !request.url.includes('/activate/');
    if (isAutoLoad) {
      this.appService.isLoading.next(true);
    }

    return next.handle(request).pipe(
      finalize(() => {
        if (isAutoLoad) {
          this.appService.isLoading.next(false);
        }
      }),
    );
  }
}
