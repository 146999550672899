import { createReducer, on } from '@ngrx/store';
import {
  addVisibleObjectsIdForCurrentUser,
  deleteVisibleObjectsIdForCurrentUser,
  storeCurrentUser,
} from '@store/actions/current_user.actions';

export const initialState = {
  currentUser: null,
};

export const currentUserReducer = createReducer(
  initialState,
  on(storeCurrentUser, (state, { currentUser }) => ({ ...state, currentUser })),
  on(addVisibleObjectsIdForCurrentUser, (state: any, { objectId }) => {
    const updatedVisibleObjectsIds = [
      ...new Set([...state.currentUser.visibleObjectsIds, objectId]),
    ];

    const updatedCurrentUser = {
      ...state.currentUser,
      visibleObjectsIds: updatedVisibleObjectsIds,
    };

    return {
      ...state,
      currentUser: updatedCurrentUser,
    };
  }),
  on(deleteVisibleObjectsIdForCurrentUser, (state: any, { objectId }) => {
    const updatedVisibleObjectsIds = state.currentUser.visibleObjectsIds.filter(
      (visibleId: any) => visibleId !== objectId,
    );

    const updatedCurrentUser = {
      ...state.currentUser,
      visibleObjectsIds: updatedVisibleObjectsIds,
    };

    return {
      ...state,
      currentUser: updatedCurrentUser,
    };
  }),
);
