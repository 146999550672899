import { Injectable } from '@angular/core';
import { getImportObjects, storeImportObjects } from '@store/actions/import_objects.actions';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, exhaustMap, map } from 'rxjs/operators';
import { EMPTY } from 'rxjs';
import { ImportObjectService } from '@common/services/import-object.service';

@Injectable()
export class ImportObjectsEffects {
  getImportObjects$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getImportObjects),
      exhaustMap(() =>
        this.importService.getImportsList().pipe(
          map((importProcesses) => {
            return storeImportObjects({ list: importProcesses });
          }),
          catchError(() => EMPTY),
        ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private importService: ImportObjectService,
  ) {}
}
