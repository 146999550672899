import { Component, Inject, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { AppService } from '@services/app.service';
import { TuiLanguageSwitcher } from '@taiga-ui/i18n';
import { TuiThemeNightService } from '@taiga-ui/addon-doc';
import { TuiBrightness } from '@taiga-ui/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less'],
})
export class AppComponent implements OnInit {
  title = 'phoenix-frontend';

  isLoading = this.appService.isLoading;

  get mode(): TuiBrightness | null {
    return this.night.value ? 'onDark' : null;
  }

  constructor(
    @Inject(TuiLanguageSwitcher) private readonly switcher: TuiLanguageSwitcher,
    @Inject(TuiThemeNightService) readonly night: TuiThemeNightService,
    public translate: TranslateService,
    private appService: AppService,
  ) {}

  ngOnInit(): void {
    const localStorageLanguage = localStorage.getItem('lang');
    const browserLanguage = this.translate.getBrowserLang();

    this.translate.defaultLang = environment.defaultLocale;
    // this.translate.use('en')

    if (localStorageLanguage && environment.locales.includes(localStorageLanguage)) {
      // this.translate.setDefaultLang(localStorageLanguage);
      this.translate.use(localStorageLanguage);
    } else if (browserLanguage && environment.locales.includes(browserLanguage)) {
      // this.translate.setDefaultLang(browserLanguage);
      this.translate.use(browserLanguage);
    } else {
      // this.translate.setDefaultLang(environment.defaultLocale);
      this.translate.use(environment.defaultLocale);
    }

    this.switcher.setLanguage(localStorageLanguage ?? browserLanguage ?? 'en');
  }
}
