<div class="spc" [class.reversed-animation]="context['justify-content'] === 'flex-end'">
  <button class="close" (click)="context.completeWith(false)">
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.22793 7.22703C7.52082 6.93414 7.99569 6.93414 8.28859 7.22703L12.0009 10.9393L15.7132 7.22703C16.0061 6.93414 16.481 6.93414 16.7739 7.22703C17.0668 7.51992 17.0668 7.9948 16.7739 8.28769L13.0616 12L16.7739 15.7123C17.0668 16.0052 17.0668 16.4801 16.7739 16.773C16.481 17.0659 16.0061 17.0659 15.7132 16.773L12.0009 13.0607L8.28859 16.773C7.99569 17.0659 7.52082 17.0659 7.22793 16.773C6.93503 16.4801 6.93503 16.0052 7.22793 15.7123L10.9402 12L7.22793 8.28769C6.93503 7.9948 6.93503 7.51992 7.22793 7.22703Z"
        fill="currentColor"
      />
    </svg>
  </button>
  <div class="sidepage__header">
    <h2 [id]="context.id" [tuiHint]="context.heading" [tuiHintAppearance]="'custom'">
      {{ context.heading }}
    </h2>
    <h2
      [id]="context.id"
      [tuiHint]="context.headingTranslate | translate"
      [tuiHintAppearance]="'custom'"
    >
      {{ context.headingTranslate | translate }}
    </h2>
  </div>
  <section>
    <ng-container *polymorpheusOutlet="$any(context.content) as text; context: context">
      {{ text }}
    </ng-container>
  </section>
</div>
