import { createReducer, on } from '@ngrx/store';
import { storeDeviceModels } from '@store/actions/device_models.actions';

export const initialState = {
  list: [],
};

export const deviceModelsReducer = createReducer(
  initialState,
  on(storeDeviceModels, (state, { list }) => ({ ...state, list })),
);
