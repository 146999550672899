import { createReducer, on } from '@ngrx/store';
import {
  changeListImportObjects,
  deleteImportObjectById,
  storeImportObjects,
} from '@store/actions/import_objects.actions';

export const initialState: any = {
  list: [],
};

export const importObjectsReducer = createReducer(
  initialState,
  on(storeImportObjects, (state, { list }) => ({ ...state, list })),
  on(changeListImportObjects, (state, data) => {
    const { createdObject } = data;
    const index = state.list.findIndex((object: any) => object.taskId === createdObject.taskId);
    if (index !== -1) {
      const updatedList = state.list.map((object: any) => {
        return object.taskId === createdObject.taskId ? { ...object, ...createdObject } : object;
      });
      return { ...state, list: updatedList };
    } else {
      return { ...state, list: [createdObject, ...state.list] };
    }
  }),
  on(deleteImportObjectById, (state, data) => {
    const { taskId } = data;
    return {
      ...state,
      list: state.list.filter((process: any) => process.taskId !== taskId),
    };
  }),
);
