import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class GeozonesService {
  public createGeozoneFormVisible = new BehaviorSubject(false);
  public editedGeozone = new BehaviorSubject(null);
  public createGeozoneSquare = new BehaviorSubject(0);
  public createGeozonePerimeter = new BehaviorSubject(0);
  public createGeozonePerimeterMeters = new BehaviorSubject(0);
  public createGeozoneRadius = new BehaviorSubject(0);

  public createGeozoneDestroyer = new Subject();
  public deleteGeozone: any = new Subject();
  public goToGeozoneBounds: any = new Subject();

  public currentDrawLayer: any = null;

  public actionGeozoneText: string = 'Creating geofence';

  constructor(private httpClient: HttpClient) {}

  createGeozone(body: any) {
    return this.httpClient.post('geozone/', body);
  }

  updateGeozone(geozoneId: number, body: any) {
    return this.httpClient.put(`geozone/${geozoneId}/`, body);
  }

  createGeozonesGroup(body: any) {
    return this.httpClient.post('geozones/groups/', body);
  }

  list() {
    return this.httpClient.get('geozone/');
  }

  geozonesGroupList() {
    return this.httpClient.get('geozones/groups/');
  }

  updateGeozonesGroup(body: any, geozone_id: number) {
    return this.httpClient.put(`geozones/groups/${geozone_id}/`, body);
  }

  delete(geozone_id: number) {
    return this.httpClient.delete(`geozone/${geozone_id}/`);
  }

  deleteGroupGeozones(geozone_id: number) {
    return this.httpClient.delete(`geozones/groups/${geozone_id}/`);
  }
}
