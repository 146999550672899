import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY } from 'rxjs';
import { catchError, exhaustMap, map } from 'rxjs/operators';
import { ObjectsService } from '@services/objects.service';
import { getObjects, storeObjects } from '@store/actions/objects.actions';
import { getSortedList } from '@common/utils/search-sort';

@Injectable()
export class ObjectsEffects {
  getObjects$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getObjects),
      exhaustMap(() =>
        this.objectsService.objectsList().pipe(
          map((objects: any) => {
            // this.websocketService.connectAll(objects);
            return storeObjects({ list: getSortedList(objects) });
          }),
          catchError(() => EMPTY),
        ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private objectsService: ObjectsService,
  ) {}
}
