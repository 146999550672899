import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AppService {
  public isLoading = new BehaviorSubject(false);
  visibleTabsName: string[] = [];

  constructor(private httpClient: HttpClient) {}
}
