import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY } from 'rxjs';
import { catchError, exhaustMap, map } from 'rxjs/operators';
import { DeviceModelsService } from '@services/device_models.service';
import { getDeviceModels, storeDeviceModels } from '@store/actions/device_models.actions';

@Injectable()
export class DeviceModelsEffects {
  getDeviceModels$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getDeviceModels),
      exhaustMap(() =>
        this.deviceModelsService.list().pipe(
          map((objects) => storeDeviceModels({ list: objects })),
          catchError(() => EMPTY),
        ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private deviceModelsService: DeviceModelsService,
  ) {}
}
