import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ImportObjectService {
  constructor(private httpClient: HttpClient) {}

  import(file: any) {
    const data = new FormData();
    for (let i = 0; i < file.length; i++) {
      data.append('files', file[i]);
    }
    return this.httpClient.post('objects/import/', data);
  }

  getImportsList() {
    return this.httpClient.get('objects/import_task/');
  }

  getResultImport(taskId: string) {
    return this.httpClient.get(`objects/import_task/${taskId}/`);
  }

  deleteProcessImport(taskId: string) {
    return this.httpClient.delete(`objects/import_task/${taskId}/`);
  }
}
