import { createReducer, on } from '@ngrx/store';
import { storeBuildings } from '../actions/buildings.actions';

export const initialState: any = {
  list: [],
};

export const buildingsReducer = createReducer(
  initialState,
  on(storeBuildings, (state, { list }) => ({ ...state, list })),
);
