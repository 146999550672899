import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TuiButtonModule, TuiHintModule } from '@taiga-ui/core';
import { PolymorpheusModule } from '@tinkoff/ng-polymorpheus';

import { SidePageComponent } from './sidepage.component';
import { SIDEPAGE_PROVIDER } from './sidepage.service';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [TuiButtonModule, PolymorpheusModule, CommonModule, TranslateModule, TuiHintModule],
  providers: [SIDEPAGE_PROVIDER],
  declarations: [SidePageComponent],
  exports: [SidePageComponent],
})
export class SidePageModule {}
