import { createReducer, on } from '@ngrx/store';
import { storeNotifications, updateIsActiveNotifications } from '../actions/notifications.actions';

export const initialState: any = {
  list: [],
};

export const notificationsReducer = createReducer(
  initialState,
  on(storeNotifications, (state, { list }) => ({ ...state, list })),
  on(updateIsActiveNotifications, (state, data) => {
    return {
      ...state,
      list: state.list.map((notification: any) => {
        const notice: boolean = data.id === notification.id;
        if (notice) {
          notification.isActive = data.isActive;
        }
        return { ...notification };
      }),
    };
  }),
);
