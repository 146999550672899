import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PortalComponent } from './portal/portal.component';
import { CheckPasswordTokenComponent } from '@common/components/reset-password/check-password-token/check-password-token.component';

const routes: Routes = [
  { path: '', redirectTo: '/auth/login', pathMatch: 'full' },
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: 'portal',
    component: PortalComponent,
    loadChildren: () => import('./portal/portal.module').then((m) => m.PortalModule),
  },
  { path: 'password_reset', component: CheckPasswordTokenComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
