import { createAction, props } from '@ngrx/store';

export const getCurrentUser = createAction('[CurrentUser] Get');
export const storeCurrentUser = createAction('[CurrentUser] Store', props<{ currentUser: any }>());
export const addVisibleObjectsIdForCurrentUser = createAction(
  '[CurrentUser] Add visible objects ids',
  props<{ objectId: any }>(),
);
export const deleteVisibleObjectsIdForCurrentUser = createAction(
  '[CurrentUser] Delete visible objects ids',
  props<{ objectId: any }>(),
);
