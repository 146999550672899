import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PortalService {
  public isVisibleTrackPlayer = new BehaviorSubject(false);

  public isVisibleCompareComponent = new BehaviorSubject(false);

  public isVisibleMessages = new BehaviorSubject(false);

  public isVisibleReportResult = new BehaviorSubject(false);

  public isVisibleMap = new BehaviorSubject(true);

  public isVisibleScheme = new BehaviorSubject(false);

  public isShowMapFullScreen = new BehaviorSubject(true);
}
