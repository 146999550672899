import { Injectable, Provider } from '@angular/core';
import { AbstractTuiDialogService, TUI_DIALOGS } from '@taiga-ui/cdk';
import { PolymorpheusComponent } from '@tinkoff/ng-polymorpheus';

import { SidePageComponent } from './sidepage.component';
import { SidePageOptions } from './sidepage-options';

@Injectable({
  providedIn: `root`,
})
export class SidePageService extends AbstractTuiDialogService<SidePageOptions, boolean> {
  readonly defaultOptions = {
    heading: ``,
    headingTranslate: ``,
    'justify-content': `flex-start`,
  } as const;

  readonly component = new PolymorpheusComponent(SidePageComponent);
}
export const SIDEPAGE_PROVIDER: Provider = {
  provide: TUI_DIALOGS,
  useExisting: SidePageService,
  multi: true,
};
