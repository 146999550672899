import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private httpClient: HttpClient) {}

  login(username: string | null, password: string | null) {
    return this.httpClient.post(`auth/login/`, { username, password });
  }

  resetPassword({ username, email }: { username: string; email: string }) {
    return this.httpClient.post('password_reset/', { username, email });
  }

  checkPasswordToken(passwordToken: string) {
    return this.httpClient.post('check_password_reset_token/', { passwordToken: passwordToken });
  }

  changePassword({
    newPassword,
    confirmPassword,
    password_token,
  }: {
    newPassword: string;
    confirmPassword: string;
    password_token: string;
  }) {
    return this.httpClient.post('password_reset_confirm/', {
      newPassword,
      confirmPassword,
      password_token,
    });
  }
}
