import { Component, Inject, Renderer2, Self } from '@angular/core';
import { TuiDestroyService, TuiDialog } from '@taiga-ui/cdk';
import { TuiDialogCloseService } from '@taiga-ui/core';
import { POLYMORPHEUS_CONTEXT } from '@tinkoff/ng-polymorpheus';
import { Observable } from 'rxjs';

import { SidePageOptions } from './sidepage-options';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-sidepage',
  templateUrl: './sidepage.template.html',
  styleUrls: ['./sidepage.style.less'],
  providers: [TuiDestroyService, TuiDialogCloseService, TranslateService, TranslatePipe],
})
export class SidePageComponent {
  constructor(
    @Inject(POLYMORPHEUS_CONTEXT)
    readonly context: TuiDialog<SidePageOptions, boolean>,
    @Inject(TuiDialogCloseService) close$: Observable<unknown>,
    @Self() @Inject(TuiDestroyService) destroy$: Observable<unknown>,
    public translate: TranslateService,
    private renderer: Renderer2,
  ) {
    const elements = document.querySelectorAll('.t-dialog');
    const lastElement = elements[elements.length - 1];

    this.renderer.setStyle(lastElement, 'justify-content', context['justify-content']);

    // close$
    //   .pipe(takeUntil(destroy$))
    //   .subscribe(() => this.context.$implicit.complete());
  }
}
