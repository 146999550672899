import { APP_INITIALIZER, ErrorHandler, isDevMode, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TUI_SANITIZER, TuiModeModule, TuiThemeNightModule } from '@taiga-ui/core';
import { NgDompurifySanitizer } from '@tinkoff/ng-dompurify';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TaigaModule } from './taiga-ui.module';
import { ApiInterceptor } from './api.interceptor';
import { StoreModule } from '@ngrx/store';
import { objectsReducer } from '@store/reducers/objects.reducer';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { ObjectsEffects } from '@store/effects/objects.effects';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { deviceModelsReducer } from '@store/reducers/device_models.reducer';
import { DeviceModelsEffects } from '@store/effects/device_models.effects';
import { CurrentUserEffects } from '@store/effects/current_user.effects';
import { currentUserReducer } from '@store/reducers/current_user.reducer';
import { NGX_ECHARTS_CONFIG } from 'ngx-echarts';
import { geozonesReducer } from '@store/reducers/geozones.reducer';
import { GeozonesEffects } from '@store/effects/geozones.effects';
import { NotificationsEffects } from '@store/effects/notifications.effects';
import { notificationsReducer } from '@store/reducers/notifications.reducer';
import { TUI_ENGLISH_LANGUAGE, TUI_LANGUAGE, TUI_RUSSIAN_LANGUAGE } from '@taiga-ui/i18n';
import { of } from 'rxjs';
import { DriversEffects } from '@store/effects/drivers.effects';

import { environment } from 'src/environments/environment';

import * as Sentry from '@sentry/angular-ivy';
import { driversReducer } from '@store/reducers/drivers.reducer';
import { SidePageModule } from '@common/components/sidepage/sidepage.module';
import { buildingsReducer } from '@store/reducers/buildings.reducer';
import { BuildingsEffects } from '@store/effects/buildings.effects';
import { ErrorInterceptor } from '@common/interceptors/error.interceptor';
import { Router } from '@angular/router';
import { LoaderInterceptor } from '@common/interceptors/loader.interceptor';
import { importObjectsReducer } from '@store/reducers/import_objects.reducer';
import { ImportObjectsEffects } from '@store/effects/import_objects.effects';

const production = environment.production;

function getLang() {
  const lang = localStorage.getItem('lang');
  if (lang === 'ru' || lang === 'russian') {
    return TUI_RUSSIAN_LANGUAGE;
  } else {
    return TUI_ENGLISH_LANGUAGE;
  }
}

function getProviders() {
  const providers: any = [
    { provide: TUI_SANITIZER, useClass: NgDompurifySanitizer },
    { provide: HTTP_INTERCEPTORS, useClass: ApiInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
    {
      provide: TUI_LANGUAGE,
      useValue: of(getLang()),
    },
    {
      provide: NGX_ECHARTS_CONFIG,
      useFactory: () => ({ echarts: () => import('echarts') }),
    },
  ];
  // if (!production) {
  providers.push(
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
  );
  // }
  return providers;
}

console.log('PRODUCTION', production);

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './common/assets/i18n/', '.json');
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    StoreModule.forRoot({
      objects: objectsReducer,
      geozones: geozonesReducer,
      deviceModels: deviceModelsReducer,
      currentUser: currentUserReducer,
      notifications: notificationsReducer,
      drivers: driversReducer,
      buildings: buildingsReducer,
      importObjects: importObjectsReducer,
    }),
    StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: !isDevMode() }),
    EffectsModule.forRoot([
      ObjectsEffects,
      DeviceModelsEffects,
      CurrentUserEffects,
      GeozonesEffects,
      NotificationsEffects,
      DriversEffects,
      BuildingsEffects,
      ImportObjectsEffects,
    ]),
    AppRoutingModule,
    BrowserAnimationsModule,
    TaigaModule,
    HttpClientModule,
    TranslateModule.forRoot({ defaultLanguage: 'en' }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    SidePageModule,
    TuiThemeNightModule,
    TuiModeModule,
  ],
  providers: getProviders(),
  bootstrap: [AppComponent],
})
export class AppModule {}
