import { createAction, props } from '@ngrx/store';

export const getImportObjects = createAction('[Import objects] Get');
export const storeImportObjects = createAction('[Import objects] Store', props<{ list: any }>());
export const deleteImportObjectById = createAction(
  '[Import objects] Delete import by id',
  props<{ taskId: string }>(),
);
export const changeListImportObjects = createAction(
  '[Import objects] Change list import',
  props<any>(),
);
