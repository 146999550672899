import { platformBrowserDynamic } from '@angular/platform-browser-dynamic'; // import * as Sentry from "@sentry/angular" // for Angular 10/11 instead
import * as Sentry from '@sentry/angular-ivy';

import { AppModule } from './app/app.module';
import { enableProdMode } from '@angular/core';
import { environment } from './environments/environment';

Sentry.init({
  dsn: 'https://f71d85044936c52957b2b128cb6ac664@sentry.axenta.cloud/2',
  environment: environment.production ? 'production' : 'development', // Установите окружение в зависимости от режима,
});

enableProdMode();
platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .then((success) => console.log('Bootstrap success'))
  .catch((err) => console.error(err));
