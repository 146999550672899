import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/services/auth.service';

@Component({
  selector: 'app-check-password-token',
  templateUrl: './check-password-token.component.html',
  styleUrls: ['./check-password-token.component.less'],
})
export class CheckPasswordTokenComponent implements OnInit {
  constructor(
    private authService: AuthService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe((params: any) => {
      const password_token = params.password_token;
      this.authService.checkPasswordToken(password_token).subscribe({
        next: () => {
          this.router.navigate(['/auth/login'], {
            queryParams: { password_token: password_token },
          });
        },
        error: () => {
          this.router.navigateByUrl('/auth/login');
        },
      });
    });
  }
}
