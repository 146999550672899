import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class NotificationsService {
  constructor(private httpClient: HttpClient) {}

  list() {
    return this.httpClient.get('notifications/');
  }

  listEvents(page: number, perPage: number, search?: string | null) {
    let params = new HttpParams().set('page', page.toString()).set('per_page', perPage.toString());

    if (search) {
      params = params.set('search', search);
    }

    return this.httpClient.get('notification_events/', { params });
  }

  createNotification(body: any) {
    return this.httpClient.post('notifications/', body);
  }

  editNotification(body: any, id: any) {
    return this.httpClient.put(`notifications/${id}/`, body);
  }

  patchNotification(body: any, id: any) {
    return this.httpClient.patch(`notifications/${id}/`, body);
  }

  deleteNotice(id: number) {
    return this.httpClient.delete(`notifications/${id}`);
  }
}
