export interface TabInfo {
  name: PortalTabsE;
  title: string;
  routerLink: string;
  iconSrc: string;
  id: number;
}

export enum PortalTabsE {
  Monitoring = 'monitoring',
  Tracks = 'tracks',
  Reports = 'reports',
  Messages = 'messages',
  Notifications = 'notifications',
  Geofences = 'geofences',
  Drivers = 'drivers',
  Objects = 'objects',
  Users = 'users',
  Buildings = 'buildings',
  Devices = 'devices',
}

export const monitoringTabs: TabInfo[] = [
  {
    name: PortalTabsE.Monitoring,
    title: 'Monitoring',
    routerLink: `/portal/${PortalTabsE.Monitoring}`,
    iconSrc: 'common/assets/icons/monitoring.svg',
    id: 1,
  },
  {
    name: PortalTabsE.Tracks,
    title: 'Tracks',
    routerLink: `/portal/${PortalTabsE.Tracks}`,
    iconSrc: 'common/assets/icons/tracks.svg',
    id: 2,
  },
  {
    name: PortalTabsE.Reports,
    title: 'Reports',
    routerLink: `/portal/${PortalTabsE.Reports}`,
    iconSrc: 'common/assets/icons/reports.svg',
    id: 3,
  },
  {
    name: PortalTabsE.Messages,
    title: 'Messages',
    routerLink: `/portal/${PortalTabsE.Messages}`,
    iconSrc: 'common/assets/icons/messages.svg',
    id: 4,
  },
  {
    name: PortalTabsE.Notifications,
    title: 'Notifications',
    routerLink: `/portal/${PortalTabsE.Notifications}`,
    iconSrc: 'common/assets/icons/notifications.svg',
    id: 5,
  },
  {
    name: PortalTabsE.Geofences,
    title: 'Geofences',
    routerLink: `/portal/${PortalTabsE.Geofences}`,
    iconSrc: 'common/assets/icons/geozones.svg',
    id: 6,
  },
  {
    name: PortalTabsE.Drivers,
    title: 'Drivers',
    routerLink: `/portal/${PortalTabsE.Drivers}`,
    iconSrc: 'common/assets/icons/drivers.svg',
    id: 7,
  },
  {
    name: PortalTabsE.Objects,
    title: 'Objects',
    routerLink: `/portal/${PortalTabsE.Objects}`,
    iconSrc: 'common/assets/icons/objects.svg',
    id: 8,
  },
  {
    name: PortalTabsE.Users,
    title: 'Users',
    routerLink: `/portal/${PortalTabsE.Users}`,
    iconSrc: 'common/assets/icons/users.svg',
    id: 9,
  },
  {
    name: PortalTabsE.Buildings,
    title: 'Buildings',
    routerLink: `/portal/${PortalTabsE.Buildings}`,
    iconSrc: 'common/assets/icons/building.svg',
    id: 10,
  },
  {
    name: PortalTabsE.Devices,
    title: 'Devices',
    routerLink: `/portal/${PortalTabsE.Devices}`,
    iconSrc: 'common/assets/icons/devices.svg',
    id: 11,
  },
];
