import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class DriversService {
  constructor(private httpClient: HttpClient) {}

  createDriver(form: any) {
    return this.httpClient.post('drivers/', form);
  }

  createDriversGroup(form: any) {
    return this.httpClient.post('drivers/groups/', form);
  }

  updateDriver(form: any, driverId: number) {
    return this.httpClient.put(`drivers/${driverId}/`, form);
  }

  updateDriversGroup(form: any, driverId: number) {
    return this.httpClient.put(`drivers/groups/${driverId}/`, form);
  }

  assignDriver(objectId: number, driverId: number) {
    return this.httpClient.post(`driver/attach/`, { driverId, objectId });
  }

  deAttachDriver(driverId: number) {
    return this.httpClient.post(`driver/deattach/`, { driverId });
  }

  appointmentHistory(driverId: number) {
    return this.httpClient.get(`driver/history/${driverId}/`);
  }

  driversList() {
    return this.httpClient.get('drivers/');
  }

  driversGroupList() {
    return this.httpClient.get('drivers/groups/');
  }

  deleteDriver(driverId: number) {
    return this.httpClient.delete(`drivers/${driverId}`);
  }

  deleteGroupDrivers(driverId: number) {
    return this.httpClient.delete(`drivers/groups/${driverId}`);
  }
}
