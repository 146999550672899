import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BuildingService {
  currentBuilding = new BehaviorSubject<any>(null);
  roomOpened = new BehaviorSubject<any>(null);
  activeDevice = new BehaviorSubject<any>(null);

  constructor(private httpClient: HttpClient) {}

  list() {
    return this.httpClient.get<any[]>('building/');
  }

  create(body: any) {
    return this.httpClient.post('building/', body);
  }

  update(buildingId: number, body: any) {
    return this.httpClient.put(`building/${buildingId}/`, body);
  }

  deleteBuilding(buildingId: number) {
    return this.httpClient.delete(`building/${buildingId}/`);
  }

  deleteRoom(roomId: number) {
    return this.httpClient.delete(`building_rooms/${roomId}/`);
  }

  createRoom(name: any, beaconsCoordinates: any, schemeFile: any, buildingId: number) {
    const data = new FormData();
    data.append('name', name);
    data.append('building', String(buildingId));
    data.append('devicesPositions', String(JSON.stringify(beaconsCoordinates)));
    data.append('schema', schemeFile);
    return this.httpClient.post('building_rooms/?devices=[]', data);
  }

  updateRoom(
    roomId: number,
    name: any,
    beaconsCoordinates: any,
    schemeFile: any,
    buildingId: number,
  ) {
    const data = new FormData();
    data.append('name', name);
    data.append('buildingId', String(buildingId));
    data.append('devicesPositions', String(JSON.stringify(beaconsCoordinates)));
    if (schemeFile) {
      data.append('schema', schemeFile);
    }
    return this.httpClient.patch(`building_rooms/${roomId}/`, data);
  }

  getRoomInfo(roomId: number) {
    return this.httpClient.get<any[]>(`room_info/${roomId}/`);
  }
}
