import { Component, HostListener, Inject, OnInit, SimpleChanges } from '@angular/core';
import { Store } from '@ngrx/store';
import { getCurrentUser } from '@store/actions/current_user.actions';
import { getDeviceModels } from '@store/actions/device_models.actions';
import { getObjects } from '@store/actions/objects.actions';
import { selectObjectsByIds } from '@store/selectors/objects.selectors';
import { MapService } from './components/map/map.service';
import { PortalService } from '@services/portal.service';
import { getGeozones } from '@store/actions/geozones.actions';
import { getBuildings } from '@store/actions/buildings.actions';
import { getDrivers } from '@store/actions/drivers.actions';
import { TuiThemeNightService } from '@taiga-ui/addon-doc';
import { selectCurrentUser } from '@store/selectors/current_user.selectors';
import { getImportObjects } from '@store/actions/import_objects.actions';
import { selectImportObjects } from '@store/selectors/import_objects.selector';
import { TuiDestroyService } from '@taiga-ui/cdk';
import { filter, skip, take, takeUntil } from 'rxjs';
import { appLoaded } from '@store/selectors/app.selectors';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { PortalTabsE } from '@common/utils/consts/monitoring-tabs-config';

export const tabAccessMapping: any = {
  [PortalTabsE.Monitoring]: true,
  [PortalTabsE.Objects]: true,
  [PortalTabsE.Users]: true,
  [PortalTabsE.Tracks]: 'access_tracks',
  [PortalTabsE.Reports]: 'access_reports',
  [PortalTabsE.Messages]: 'access_messages',
  [PortalTabsE.Notifications]: 'access_notifications',
  [PortalTabsE.Geofences]: 'access_geozones',
  [PortalTabsE.Drivers]: 'access_drivers',
  [PortalTabsE.Buildings]: 'access_buildings',
  [PortalTabsE.Devices]: 'access_devices',
};

@Component({
  selector: 'app-portal',
  templateUrl: './portal.component.html',
  styleUrls: ['./portal.component.less'],
  providers: [TuiDestroyService],
})
export class PortalComponent implements OnInit {
  widthPortal: string = '500px';
  heightMessages = window.innerHeight;
  topValue = this.heightMessages / 2;

  appLoading: boolean = true;

  visibleObjectsIds: number[] = [];
  lastCommonAccesses: string[] = [];

  importObjectsList$ = this.store.select(selectImportObjects);

  isVisibleMessages = false;
  isVisibleReport = false;

  initObject = null;
  initAddress = null;

  objectsInClusterForPopup = null;

  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    if (!this.isVisibleMessages && !this.isVisibleReport) {
      this.heightMessages = window.innerHeight;
    }
  }

  constructor(
    @Inject(TuiThemeNightService) readonly night: TuiThemeNightService,
    private store: Store,
    private mapService: MapService,
    public portalService: PortalService,
    private destroy$: TuiDestroyService,
    private router: Router,
    private location: Location,
  ) {
    this.night.next(!!window.localStorage.getItem('theme') || false);
  }

  getSegmentCurrentRoute(): string {
    const currentUrl = this.router.routerState.snapshot.url;
    return currentUrl.substring(currentUrl.lastIndexOf('/') + 1);
  }
  onUpdateObjectInfo($event: any) {
    if ($event) {
      this.initObject = $event;
    } else return;
  }

  onUpdateObjectAddress($event: any) {
    if ($event) {
      this.initAddress = $event;
    } else return;
  }
  onUpdateObjectsInCluster($event: any) {
    this.objectsInClusterForPopup = $event;
  }

  closeClusterPopup($event: any) {
    this.objectsInClusterForPopup = null;
  }

  onMouseLeaveMapCluster($event: any) {
    const relatedTargetClassList = $event.relatedTarget?.offsetParent?.classList.value;
    if (
      relatedTargetClassList?.includes('popup-cluster-container') ||
      relatedTargetClassList?.includes('popup-closure-marker') ||
      relatedTargetClassList?.includes('object') ||
      relatedTargetClassList?.includes('popup-container') ||
      relatedTargetClassList?.includes('object-tooltip')
    ) {
      return;
    } else {
      this.objectsInClusterForPopup = null;
    }
  }

  closePopup($event: any) {
    this.initObject = null;
  }

  onMouseClickMap() {
    this.initObject = null;
  }

  onMouseLeaveMap($event: any) {
    const relatedTargetClassList = $event.relatedTarget?.offsetParent?.classList.value;
    if (
      relatedTargetClassList?.includes('popup-container') ||
      relatedTargetClassList?.includes('object-tooltip') ||
      relatedTargetClassList?.includes('popup-closure-marker')
    ) {
      return;
    } else {
      this.initObject = null;
    }
  }
  ngOnInit(): void {
    this.store.dispatch(getObjects());
    this.store.dispatch(getDeviceModels());
    this.store.dispatch(getCurrentUser());
    // this.store.dispatch(getGeozones());
    this.store.dispatch(getBuildings());
    this.store.dispatch(getDrivers());
    this.store.dispatch(getImportObjects());
    // TODO: maybe сделать что бы в сторе хранились id выбранных объектов и подписываться на них в карте
    // this.store.select(selectObjects).subscribe((objects: any) => {
    //   this.mapService.selectedObjects.next(objects);
    // });

    this.store
      .select(selectCurrentUser)
      .pipe(
        takeUntil(this.destroy$),
        filter((user) => !!user),
      )
      .subscribe((user) => {
        if (!user || !this.arraysEqual(this.visibleObjectsIds, user?.visibleObjectsIds).equal) {
          this.visibleObjectsIds = [...(user?.visibleObjectsIds || [])];

          if (this.isVisibleReport || this.isVisibleMessages) return;

          this.store
            .select((state) => selectObjectsByIds(state, user?.visibleObjectsIds || []))
            .pipe(take(1))
            .subscribe((objects) => {
              // if (objects?.length)
              this.mapService.selectedObjects.next({ objects: objects, isFitBounds: false });
            });
        }
        this.handleCommonAccesses(user?.commonAccesses || []);
      });

    this.store
      .select((state) => selectObjectsByIds(state, this.visibleObjectsIds))
      .pipe(takeUntil(this.destroy$))
      .subscribe((objects) => {
        this.mapService.drawMapObjects.next(objects);
      });

    this.portalService.isVisibleMessages.pipe(takeUntil(this.destroy$)).subscribe((isVisible) => {
      this.heightMessages = isVisible ? this.topValue : window.innerHeight;
      this.isVisibleMessages = isVisible;
    });

    this.portalService.isVisibleReportResult
      .pipe(takeUntil(this.destroy$))
      .subscribe((isVisible) => {
        this.heightMessages = isVisible ? this.topValue : window.innerHeight;
        this.isVisibleReport = isVisible;
      });

    const storedWidth = parseInt(localStorage.getItem('widthPortal') || '');
    this.widthPortal = isNaN(storedWidth) ? this.widthPortal : `${storedWidth}px`;

    this.store
      .select(appLoaded)
      .pipe(skip(1))
      .subscribe((loaded) => {
        this.appLoading = !loaded;
      });
  }

  onChangeSizeContentRoute($event: readonly [x: number, y: number]) {
    localStorage.setItem('widthPortal', `${$event[0]}`);
  }

  onChangeSizeMap($event: readonly [x: number, y: number]) {
    this.topValue = $event[1];
    if (this.topValue < 100) {
      this.topValue = 100;
    } else if (this.topValue > window.innerHeight - 200) {
      this.topValue = window.innerHeight - 200;
    }
  }

  handleCommonAccesses(commonAccesses: string[] = []) {
    const comparisonCommonAccesses: {
      equal: boolean;
      missingInB: any[];
      missingInA: any[];
    } = this.arraysEqual(this.lastCommonAccesses, commonAccesses);

    if (!comparisonCommonAccesses.equal) {
      this.lastCommonAccesses = [...commonAccesses];

      if (
        comparisonCommonAccesses.missingInA.includes('access_geozones') ||
        comparisonCommonAccesses.missingInB.includes('access_geozones')
      ) {
        this.store.dispatch(getGeozones());
      }

      if (this.isVisibleReport || this.isVisibleMessages) return;

      if (!this.checkCurrentPageByCommonAccesses(commonAccesses)) {
        this.router.navigate([`/portal/${PortalTabsE.Monitoring}`]).then();
      }
    }
  }

  checkCurrentPageByCommonAccesses(commonAccesses: string[]) {
    const currentPath = this.getSegmentCurrentRoute();

    return (
      currentPath in tabAccessMapping &&
      (tabAccessMapping[currentPath] === true ||
        commonAccesses.includes(tabAccessMapping[currentPath]))
    );
  }

  arraysEqual(a: any[], b: any[]) {
    if (a == null || b == null) {
      return { equal: false, missingInB: [], missingInA: [] };
    }

    const equal = a.length === b.length && a.every((value, index) => value === b[index]);

    const missingInB = a.filter((item) => !b.includes(item));
    const missingInA = b.filter((item) => !a.includes(item));

    return { equal, missingInB, missingInA };
  }
}
