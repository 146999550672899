import { createReducer, on } from '@ngrx/store';
import { storeDrivers } from '../actions/drivers.actions';

export const initialState: any = {
  list: [],
};

export const driversReducer = createReducer(
  initialState,
  on(storeDrivers, (state, { list }) => ({ ...state, list })),
);
